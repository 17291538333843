import Cookies from 'js-cookie';

const setVisitorIdCookie = (tempVisitorId) => {
  if (!Cookies.get('th_visitor_id')) {
    Cookies.set('th_visitor_id', tempVisitorId, {
      expires: 3650,
    });
  }
};

const setUtmCookie = () => {
  const validUtmParams = [
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
    'd',
  ];

  const searchParams = new URLSearchParams(window.location.search);
  const utmCookieValue = {};

  validUtmParams.forEach((utmParam) => {
    if (searchParams.has(utmParam)) {
      utmCookieValue[utmParam] = searchParams.get(utmParam);
    }
  });

  if (Object.keys(utmCookieValue).length) {
    Cookies.set('signup_metadata', JSON.stringify(utmCookieValue), {
      expires: 14,
    });
  }
};

export default { setVisitorIdCookie, setUtmCookie };
