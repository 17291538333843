import track from '../lib/tracking/track';
import visitorCookies from '../packs/visitor-cookies';

const uaMetaContent = (name) => {
  const element = document.querySelector(`meta[name='ua:${name}']`);
  return element ? element.getAttribute('content') : undefined;
};

const trackPageView = () => {
  const eventName = uaMetaContent('event_name');
  const eventProperties = uaMetaContent('event_properties');
  if (eventName) {
    track(eventName, parseProperties(eventProperties));
  }
};

const parseProperties = (rawProperties = null) => JSON.parse(rawProperties);

const setVisitorIdCookie = () => {
  const tempVisitorId = uaMetaContent('temp_visitor_id');
  // eslint-disable-next-line no-unused-expressions
  tempVisitorId && visitorCookies.setVisitorIdCookie(tempVisitorId);
};

document.addEventListener('functional_cookies_enabled', () => {
  setVisitorIdCookie();
  visitorCookies.setUtmCookie();
  trackPageView();
});

export default trackPageView;
